import React from 'react';
import { motion } from 'framer-motion';
import { Ruler, Plane } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { Card, CardContent } from '@/components/ui/card';
import Header from '@/components/ui/header';
import { useNavigate } from 'react-router-dom';
import Footer from '@/components/ui/footer';

interface ToolCardProps {
  icon: React.ComponentType<React.SVGProps<SVGSVGElement>>;
  title: string;
  description: string;
  onClick: () => void;
}

const ToolCard: React.FC<ToolCardProps> = ({ icon: Icon, title, description, onClick }) => (
  <Card className="bg-white dark:bg-gray-800 shadow-lg hover:shadow-xl transition-shadow duration-300">
    <CardContent className="flex flex-col items-center p-6">
      <Icon className="w-12 h-12 mb-4 text-blue-600 dark:text-blue-400" />
      <h3 className="text-xl font-semibold mb-2 text-gray-800 dark:text-gray-200">{title}</h3>
      <p className="text-gray-600 dark:text-gray-400 text-center mb-4">{description}</p>
      <Button onClick={onClick} className="mt-auto">
        Launch Tool
      </Button>
    </CardContent>
  </Card>
);

const ToolsPage: React.FC = () => {
  const navigate = useNavigate();
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
        delayChildren: 0.2,
      },
    },
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        type: 'spring',
        stiffness: 100,
      },
    },
  };

  const handleRunwayPrediction = () => {
    console.log('Launching Runway Prediction Tool');
    navigate('/tools/runways');
  };

  const handleAircraftIdentification = () => {
    console.log('Launching Aircraft Identification Tool');
    navigate('/tools/aircraft-identification');
  };

  return (
    <div className="min-h-screen bg-gray-50 dark:bg-gray-900 text-gray-800 dark:text-gray-200 transition-colors duration-300">
      <Header onNavigate={(page) => console.log(`Navigating to ${page}`)} />
      
      <main className="container mx-auto px-4 py-12">
        <motion.div
          initial="hidden"
          animate="visible"
          variants={containerVariants}
          className="max-w-4xl mx-auto"
        >
          <motion.h1
            variants={itemVariants}
            className="text-4xl md:text-5xl font-bold mb-6 text-center text-gray-900 dark:text-gray-100"
          >
            Advanced Aviation Tools
          </motion.h1>
          <motion.p
            variants={itemVariants}
            className="text-xl mb-8 text-center text-gray-700 dark:text-gray-300"
          >
            Explore our collection of powerful tools designed for aviation enthusiasts and professionals.
          </motion.p>

          <motion.div
            variants={containerVariants}
            className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-8"
          >
            <motion.div variants={itemVariants} className="flex">
              <div className="flex flex-col flex-grow h-full">
                <ToolCard
                  icon={Ruler}
                  title="Runway Prediction"
                  description="Predict the most likely runway for landing based on current weather conditions and airport data."
                  onClick={handleRunwayPrediction}
                />
              </div>
            </motion.div>
            <motion.div variants={itemVariants} className="flex">
              <div className="flex flex-col flex-grow h-full">
                <ToolCard
                  icon={Plane}
                  title="Aircraft Identification"
                  description="Identify aircraft types from images using advanced machine learning algorithms."
                  onClick={handleAircraftIdentification}
                />
              </div>
            </motion.div>
          </motion.div>
        </motion.div>
      </main>

      <Footer />
    </div>
  );
};

export default ToolsPage;