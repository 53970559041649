import React from 'react';
import { motion } from 'framer-motion';
import { Pencil, Camera, Users } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { Card, CardContent } from '@/components/ui/card';
import Header from '@/components/ui/header';
import { useNavigate } from 'react-router-dom';
import Footer from '@/components/ui/footer';

interface FeatureCardProps {
  icon: React.ComponentType<React.SVGProps<SVGSVGElement>>;
  title: string;
  description: string;
  onClick?: () => void;
}

const FeatureCard: React.FC<FeatureCardProps> = ({ icon: Icon, title, description, onClick }) => (
  <Card className="bg-white dark:bg-gray-800 shadow-lg hover:shadow-xl transition-shadow duration-300" onClick={onClick}>
    <CardContent className="flex flex-col items-center p-6">
      <Icon className="w-12 h-12 mb-4 text-blue-600 dark:text-blue-400" />
      <h3 className="text-xl font-semibold mb-2 text-gray-800 dark:text-gray-200">{title}</h3>
      <p className="text-gray-600 dark:text-gray-400 text-center">{description}</p>
    </CardContent>
  </Card>
);

const HomePage = () => {
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
        delayChildren: 0.2,
      },
    },
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        type: 'spring',
        stiffness: 100,
      },
    },
  };

  const navigate = useNavigate();

  return (
    <div className="min-h-screen bg-gray-50 dark:bg-gray-900 text-gray-800 dark:text-gray-200 transition-colors duration-300">
      <Header onNavigate={(page) => console.log(`Navigating to ${page}`)} />
      
      <main className="container mx-auto px-4 py-12">
        <motion.div
          initial="hidden"
          animate="visible"
          variants={containerVariants}
          className="max-w-4xl mx-auto"
        >
          <motion.h1 variants={itemVariants} className="text-4xl md:text-5xl font-bold mb-6 text-center text-gray-900 dark:text-gray-100">
            Welcome to SpotterTools.org
          </motion.h1>
          <motion.p variants={itemVariants} className="text-xl mb-8 text-center text-gray-700 dark:text-gray-300">
            Your professional platform for aviation enthusiasts. Track flights, share photos, and connect with the community.
          </motion.p>
          <motion.div variants={itemVariants} className="text-center mb-16">
            <Button 
              onClick={() => navigate('/tools')}
              className="bg-blue-600 hover:bg-blue-700 text-white transition-colors dark:bg-blue-700 dark:hover:bg-blue-600"
            >
              View Tools
            </Button>
          </motion.div>

          <motion.div 
            variants={containerVariants} 
            className="grid grid-cols-1 md:grid-cols-3 gap-8"
          >
            <motion.div variants={itemVariants}>
              <FeatureCard 
                icon={Pencil} 
                title="Advanced Tools" 
                description="Access cutting-edge aviation tools for enthusiasts and professionals."
                onClick={() => console.log('Navigate to Tools Page')}
              />
            </motion.div>
            <motion.div variants={itemVariants}>
              <FeatureCard 
                icon={Camera} 
                title="Photo Sharing" 
                description="Showcase your best aviation photographs with our community."
              />
            </motion.div>
            <motion.div variants={itemVariants}>
              <FeatureCard 
                icon={Users} 
                title="Community Forums" 
                description="Engage with fellow aviation enthusiasts from across the globe."
              />
            </motion.div>
          </motion.div>
        </motion.div>
      </main>
      <Footer />
    </div>
  );
};

export default HomePage;