import React from 'react';
import { motion } from 'framer-motion';
import { ShieldCheck, FileText, Lock, Globe } from 'lucide-react';
import { Card, CardContent } from '@/components/ui/card';
import Header from '@/components/ui/header';
import Footer from '@/components/ui/footer';

const PrivacyPolicyPage: React.FC = () => {
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
        delayChildren: 0.2,
      },
    },
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        type: 'spring',
        stiffness: 100,
      },
    },
  };

  return (
    <div className="min-h-screen bg-gray-50 dark:bg-gray-900 text-gray-800 dark:text-gray-200 transition-colors duration-300">
      <Header onNavigate={(page) => console.log(`Navigating to ${page}`)} />

      <main className="container mx-auto px-4 py-12">
        <motion.div
          initial="hidden"
          animate="visible"
          variants={containerVariants}
          className="max-w-4xl mx-auto"
        >
          <motion.h1 variants={itemVariants} className="text-4xl md:text-5xl font-bold mb-6 text-center text-gray-900 dark:text-gray-100">
            Privacy Policy
          </motion.h1>

          <motion.p variants={itemVariants} className="text-xl mb-8 text-center text-gray-700 dark:text-gray-300">
            This Privacy Policy explains how we collect, use, disclose, and protect your personal information in accordance with applicable laws and regulations.
          </motion.p>

          <motion.div variants={containerVariants} className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-12">
            {/* Data Collection Section */}
            <motion.div variants={itemVariants}>
              <Card className="h-full">
                <CardContent className="p-6">
                  <div className="flex items-center mb-4">
                    <ShieldCheck className="w-8 h-8 mr-3 text-blue-600 dark:text-blue-400" />
                    <h2 className="text-2xl font-semibold">Data Collection</h2>
                  </div>
                  <p className="text-gray-600 dark:text-gray-400">
                    We may collect various types of personal data, including but not limited to:
                  </p>
                  <ul className="list-disc ml-6 text-gray-600 dark:text-gray-400 mt-4 space-y-2">
                    <li>Identifying Information (e.g., name, email address, phone number)</li>
                    <li>Transaction Information (e.g., payment history, transaction data)</li>
                    <li>Device Information (e.g., IP address, browser type, operating system)</li>
                    <li>Location Data (if enabled by you)</li>
                  </ul>
                  <p className="text-gray-600 dark:text-gray-400 mt-4">
                    We collect this information when you register, interact with our services, or communicate with us.
                  </p>
                </CardContent>
              </Card>
            </motion.div>

            {/* Data Usage Section */}
            <motion.div variants={itemVariants}>
              <Card className="h-full">
                <CardContent className="p-6">
                  <div className="flex items-center mb-4">
                    <FileText className="w-8 h-8 mr-3 text-blue-600 dark:text-blue-400" />
                    <h2 className="text-2xl font-semibold">Data Usage</h2>
                  </div>
                  <p className="text-gray-600 dark:text-gray-400">
                    The data we collect is used for various purposes, including:
                  </p>
                  <ul className="list-disc ml-6 text-gray-600 dark:text-gray-400 mt-4 space-y-2">
                    <li>To provide, maintain, and improve our services</li>
                    <li>To process your transactions and manage your account</li>
                    <li>To communicate with you, including sending updates and notifications</li>
                    <li>To comply with legal obligations</li>
                  </ul>
                  <p className="text-gray-600 dark:text-gray-400 mt-4">
                    We do not share your personal data with third parties, except as required by law or with your consent. Data is stored securely, and access is restricted.
                  </p>
                </CardContent>
              </Card>
            </motion.div>
          </motion.div>

          {/* Legal Rights Section */}
          <motion.h2 variants={itemVariants} className="text-3xl font-bold mb-6 text-center text-gray-900 dark:text-gray-100">
            Your Rights and Choices
          </motion.h2>

          <motion.div variants={containerVariants} className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-12">
            <motion.div variants={itemVariants}>
              <Card className="h-full">
                <CardContent className="p-6">
                  <div className="flex items-center mb-4">
                    <Lock className="w-8 h-8 mr-3 text-blue-600 dark:text-blue-400" />
                    <h2 className="text-2xl font-semibold">Your Privacy Rights</h2>
                  </div>
                  <p className="text-gray-600 dark:text-gray-400">
                    You have certain rights regarding the personal data we collect, including:
                  </p>
                  <ul className="list-disc ml-6 text-gray-600 dark:text-gray-400 mt-4 space-y-2">
                    <li>The right to access and request a copy of your data</li>
                    <li>The right to rectify inaccurate data</li>
                    <li>The right to request deletion of your data (subject to legal exceptions)</li>
                    <li>The right to object to or restrict processing of your data</li>
                    <li>The right to data portability</li>
                    <li>The right to withdraw consent at any time</li>
                  </ul>
                  <p className="text-gray-600 dark:text-gray-400 mt-4">
                    To exercise any of these rights, please contact us at <strong>privacy@spottertools.org</strong>.
                  </p>
                </CardContent>
              </Card>
            </motion.div>

            {/* International Data Transfers Section */}
            <motion.div variants={itemVariants}>
              <Card className="h-full">
                <CardContent className="p-6">
                  <div className="flex items-center mb-4">
                    <Globe className="w-8 h-8 mr-3 text-blue-600 dark:text-blue-400" />
                    <h2 className="text-2xl font-semibold">International Data Transfers</h2>
                  </div>
                  <p className="text-gray-600 dark:text-gray-400">
                    Your personal data may be transferred to, and processed in, countries other than the country in which you are resident. These countries may have different data protection laws. In such cases, we ensure that your data is protected in accordance with applicable laws and this Privacy Policy, including by entering into standard contractual clauses.
                  </p>
                </CardContent>
              </Card>
            </motion.div>
          </motion.div>
        </motion.div>
      </main>
        <Footer />
    </div>
  );
};

export default PrivacyPolicyPage;
