import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Plane, Moon, Sun, Menu, X } from 'lucide-react';
import { Link } from 'react-router-dom';
import { Button } from '@/components/ui/button';

interface HeaderProps {
    onNavigate: (page: 'home' | 'about' | 'tools') => void;
}

const Header: React.FC<HeaderProps> = ({ onNavigate }) => {
    const [darkMode, setDarkMode] = useState(() => {
        const savedMode = localStorage.getItem('darkMode');
        return savedMode !== null ? JSON.parse(savedMode) : window.matchMedia('(prefers-color-scheme: dark)').matches;
    });
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

    useEffect(() => {
        if (darkMode) {
            document.body.classList.add('dark');
        } else {
            document.body.classList.remove('dark');
        }
        localStorage.setItem('darkMode', JSON.stringify(darkMode));
    }, [darkMode]);

    const toggleDarkMode = () => {
        setDarkMode((prevMode: any) => !prevMode);
    };

    const toggleMobileMenu = () => {
        setMobileMenuOpen((prevOpen) => !prevOpen);
    };

    const menuItems = ['Home', 'Tools', 'About'];

    return (
        <header className="relative z-10 bg-transparent p-6 dark:bg-gray-900 transition-colors duration-300">
            <div className="container mx-auto flex justify-between items-center">
                <motion.h1
                    className="text-2xl md:text-4xl font-bold flex items-center cursor-pointer dark:text-white"
                    initial={{ opacity: 0, y: -20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5 }}
                >
                    <Link to="/" onClick={() => onNavigate('home')} className="flex items-center">
                        <Plane className="mr-3 transform rotate-45" size={28} />
                        <span className="hidden sm:inline">SpotterTools.Org</span>
                    </Link>
                </motion.h1>
                <nav className="hidden md:flex items-center space-x-8">
                    {menuItems.map((item, index) => (
                        <motion.div
                            key={item}
                            initial={{ opacity: 0, y: -20 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ delay: index * 0.1 }}
                        >
                            <Link
                                to={item === 'Home' ? '/' : `/${item.toLowerCase()}`}
                                onClick={() => onNavigate(item.toLowerCase() as 'home' | 'about' | 'tools')}
                                className="text-lg hover:text-blue-300 transition duration-300 relative group dark:text-gray-300 dark:hover:text-blue-300"
                            >
                                {item}
                                <span className="absolute left-0 -bottom-1 w-0 h-0.5 bg-blue-300 transition-all duration-300 group-hover:w-full"></span>
                            </Link>
                        </motion.div>
                    ))}
                    <Button
                        variant="ghost"
                        size="icon"
                        onClick={toggleDarkMode}
                        className="ml-4"
                    >
                        {darkMode ? (
                            <Sun className="h-5 w-5 text-yellow-500" />
                        ) : (
                            <Moon className="h-5 w-5 text-gray-700" />
                        )}
                        <span className="sr-only">Toggle dark mode</span>
                    </Button>
                </nav>
                <div className="md:hidden flex items-center">
                    <Button
                        variant="ghost"
                        size="icon"
                        onClick={toggleDarkMode}
                        className="mr-2"
                    >
                        {darkMode ? (
                            <Sun className="h-5 w-5 text-yellow-500" />
                        ) : (
                            <Moon className="h-5 w-5 text-gray-700" />
                        )}
                    </Button>
                    <Button
                        variant="ghost"
                        size="icon"
                        onClick={toggleMobileMenu}
                    >
                        {mobileMenuOpen ? (
                            <X className="h-6 w-6 text-gray-700 dark:text-gray-300" />
                        ) : (
                            <Menu className="h-6 w-6 text-gray-700 dark:text-gray-300" />
                        )}
                    </Button>
                </div>
            </div>
            <AnimatePresence>
                {mobileMenuOpen && (
                    <motion.div
                        initial={{ opacity: 0, y: -20 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: -20 }}
                        transition={{ duration: 0.3 }}
                        className="md:hidden absolute top-full left-0 right-0 bg-white dark:bg-gray-800 shadow-lg"
                    >
                        <nav className="flex flex-col items-center py-4">
                            {menuItems.map((item, index) => (
                                <Link
                                    key={item}
                                    to={item === 'Home' ? '/' : `/${item.toLowerCase()}`}
                                    onClick={() => {
                                        onNavigate(item.toLowerCase() as 'home' | 'about'  | 'tools');
                                        setMobileMenuOpen(false);
                                    }}
                                    className="text-lg py-2 hover:text-blue-300 transition duration-300 dark:text-gray-300 dark:hover:text-blue-300"
                                >
                                    {item}
                                </Link>
                            ))}
                        </nav>
                    </motion.div>
                )}
            </AnimatePresence>
        </header>
    );
};

export default Header;
