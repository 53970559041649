import React, { useState, useRef, useCallback } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Upload, Info, Camera, RefreshCcw, AlertTriangle } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Card, CardContent } from '@/components/ui/card';
import { Alert, AlertDescription, AlertTitle } from '@/components/ui/alert';
import Header from '@/components/ui/header';
import Footer from '@/components/ui/footer';
import { useNavigate } from 'react-router-dom';

const AircraftIdentificationPage = () => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState<any | null>(null);
  const [error, setError] = useState<string | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const imageRef = useRef<HTMLImageElement>(null);
  const navigate = useNavigate();

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: { staggerChildren: 0.1, delayChildren: 0.2 },
    },
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: { type: 'spring', stiffness: 100 },
    },
  };

  const handleFileChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setSelectedFile(file);
      const reader = new FileReader();
      reader.onload = () => {
        setPreviewUrl(reader.result as string);
      };
      reader.readAsDataURL(file);
      setResult(null);
      setError(null);
    }
  }, []);

  const handleUpload = useCallback(async () => {
    if (!selectedFile) return;

    setLoading(true);
    setError(null);
    setResult(null);
    const formData = new FormData();
    formData.append('file', selectedFile);

    try {
      const controller = new AbortController();
      const timeoutId = setTimeout(() => controller.abort(), 30000); // 30 second timeout

      const response = await fetch('https://api.spottertools.org/predict', {
        method: 'POST',
        body: formData,
        mode: 'cors',
        signal: controller.signal,
      });

      clearTimeout(timeoutId);

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Server responded with ${response.status}: ${errorText}`);
      }

      const data = await response.json();

      if (data.predictions && data.predictions.length === 0) {
        setError('No aircraft detected in the image. Please try another image.');
      } else if (data.error) {
        setError(`Error: ${data.error}. ${data.message || ''}`);
      } else {
        setResult(data);
      }
    } catch (error) {
      console.error('Error:', error);
      if ((error as Error).name === 'AbortError') {
        setError('The request timed out. Please try again or use a smaller image.');
      } else {
        setError(`Failed to identify aircraft: ${(error as Error).message}`);
      }
    } finally {
      setLoading(false);
    }
  }, [selectedFile]);

  const renderBox = useCallback(() => {
    if (!result || !imageRef.current || result.predictions.length === 0) return null;

    const detection = result.predictions[0];
    const { x, y, width, height } = detection;
    const boxStyle = {
      position: 'absolute',
      left: `${((x - width / 2) / result.image.width) * 100}%`,
      top: `${((y - height / 2) / result.image.height) * 100}%`,
      width: `${(width / result.image.width) * 100}%`,
      height: `${(height / result.image.height) * 100}%`,
      border: '2px solid #00ff00',
      boxShadow: '0 0 0 4px rgba(0,255,0,0.3), 0 0 10px rgba(0,255,0,0.5)',
      borderRadius: '8px',
      boxSizing: 'border-box',
      pointerEvents: 'none',
    };

    return (
      <motion.div
        initial={{ opacity: 0, scale: 0.8 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.5 }}
        style={boxStyle as React.CSSProperties}
      />
    );
  }, [result]);

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-50 to-gray-100 dark:from-gray-900 dark:to-gray-800 text-gray-800 dark:text-gray-200 transition-colors duration-300">
      <Header onNavigate={(page) => navigate(`/${page}`)} />
      
      <main className="container mx-auto px-4 py-12">
        <motion.div
          initial="hidden"
          animate="visible"
          variants={containerVariants}
          className="max-w-4xl mx-auto"
        >
          <motion.h1 variants={itemVariants} className="text-4xl md:text-5xl font-bold mb-6 text-center text-gray-900 dark:text-gray-100">
            Aircraft Identification Tool
          </motion.h1>
          
          <motion.p variants={itemVariants} className="text-xl mb-8 text-center text-gray-700 dark:text-gray-300">
            Upload an image of an aircraft to identify its type and details.
          </motion.p>

          <motion.div variants={itemVariants} className="mb-8">
            <Card className="overflow-hidden">
              <CardContent className="p-6">
                <div className="flex flex-wrap items-center justify-center gap-4 mb-6">
                  <Input
                    type="file"
                    accept="image/*"
                    onChange={handleFileChange}
                    className="hidden"
                    ref={fileInputRef}
                  />
                  <Button 
                    onClick={() => fileInputRef.current?.click()}
                    className="flex-1 sm:flex-none"
                    variant="outline"
                  >
                    <Upload className="mr-2 h-4 w-4" /> Select Image
                  </Button>
                  <Button 
                    onClick={handleUpload}
                    disabled={!selectedFile || loading}
                    className="flex-1 sm:flex-none"
                  >
                    {loading ? <RefreshCcw className="mr-2 h-4 w-4 animate-spin" /> : <Camera className="mr-2 h-4 w-4" />}
                    {loading ? 'Identifying...' : 'Identify Aircraft'}
                  </Button>
                </div>
                <AnimatePresence>
                  {previewUrl && (
                    <motion.div 
                      className="relative rounded-lg overflow-hidden shadow-lg"
                      initial={{ opacity: 0, y: 20 }}
                      animate={{ opacity: 1, y: 0 }}
                      exit={{ opacity: 0, y: -20 }}
                      transition={{ duration: 0.5 }}
                      style={{
                        width: '100%',
                        paddingTop: '56.25%', // 16:9 aspect ratio
                        backgroundColor: '#f0f0f0',
                      }}
                    >
                      <img 
                        src={previewUrl} 
                        alt="Selected aircraft" 
                        className="absolute top-0 left-0 w-full h-full object-contain"
                        ref={imageRef}
                      />
                      {renderBox()}
                    </motion.div>
                  )}
                </AnimatePresence>
              </CardContent>
            </Card>
          </motion.div>

          <AnimatePresence>
            {error && (
              <motion.div
                variants={itemVariants}
                initial="hidden"
                animate="visible"
                exit="hidden"
              >
                <Alert className="flex items-center gap-2 p-4 bg-red-100 text-red-700 rounded-lg">
                  <AlertTriangle className="h-4 w-4" />
                  <div>
                    <AlertTitle className="font-bold">Error</AlertTitle>
                    <AlertDescription>{error}</AlertDescription>
                    {selectedFile && (
                      <p className="mt-2 text-sm">
                        File: {selectedFile.name} ({selectedFile.type}, {selectedFile.size} bytes)
                      </p>
                    )}
                  </div>
                </Alert>
              </motion.div>
            )}
          </AnimatePresence>

          <AnimatePresence>
            {result && result.predictions.length > 0 && (
              <motion.div
                variants={itemVariants}
                initial="hidden"
                animate="visible"
                exit="hidden"
              >
                <Card className="overflow-hidden">
                  <CardContent className="p-6">
                    <h2 className="text-2xl font-semibold mb-4 flex items-center">
                      <Info className="mr-2" /> Aircraft Details
                    </h2>
                    <motion.div
                      initial={{ opacity: 0, x: -20 }}
                      animate={{ opacity: 1, x: 0 }}
                      transition={{ delay: 0.2 }}
                    >
                      <p className="text-lg"><strong>Type:</strong> {result.predictions[0].class}</p>
                    </motion.div>
                    <motion.div
                      initial={{ opacity: 0, x: -20 }}
                      animate={{ opacity: 1, x: 0 }}
                      transition={{ delay: 0.4 }}
                    >
                      <p className="text-lg"><strong>Confidence:</strong> {(result.predictions[0].confidence * 100).toFixed(2)}%</p>
                    </motion.div>
                    <motion.div
                      initial={{ opacity: 0, x: -20 }}
                      animate={{ opacity: 1, x: 0 }}
                      transition={{ delay: 0.6 }}
                      className="mt-4"
                    >
                      <Button variant="outline" onClick={() => window.open(`https://en.wikipedia.org/wiki/${result.predictions[0].class.replace(' ', '_')}`, '_blank')}>
                        Learn More
                      </Button>
                    </motion.div>
                  </CardContent>
                </Card>
              </motion.div>
            )}
          </AnimatePresence>
        </motion.div>
      </main>

      <Footer />
    </div>
  );
};

export default AircraftIdentificationPage;