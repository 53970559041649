// src/App.tsx
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './index.css';
import HomePage from './pages/HomePage';
import ToolsPage from './pages/ToolsPage';
import RunwayPredictionPage from './pages/RunwayPredictionPage';
import AircraftIdentificationPage from './pages/AircraftIdentPage';
import AboutPage from './pages/AboutPage';
import PrivacyPolicyPage from './pages/PrivacyPage';
import TermsOfServicePage from './pages/TermsPage';

const App: React.FC = () => {
  return (
      <Router>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/tools" element={<ToolsPage />} />
          <Route path="/tools/runways" element={<RunwayPredictionPage />} />
          <Route path="/tools/aircraft-identification" element={<AircraftIdentificationPage />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/privacy" element={<PrivacyPolicyPage />} />
          <Route path="/terms" element={<TermsOfServicePage />} />
        </Routes>
      </Router>
  );
}

export default App;