import React from 'react';

interface AlertProps extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
}

export const Alert: React.FC<AlertProps> = ({ children, ...props }) => (
  <div role="alert" {...props}>{children}</div>
);

export const AlertTitle: React.FC<AlertProps> = ({ children, ...props }) => (
  <h5 {...props}>{children}</h5>
);

export const AlertDescription: React.FC<AlertProps> = ({ children, ...props }) => (
  <div {...props}>{children}</div>
);
