import React from 'react';
import { motion } from 'framer-motion';
import { Button } from '@/components/ui/button'; // Assuming you have a Button component or can customize
import { useNavigate } from 'react-router-dom';

const Footer: React.FC = () => {
    const navigate = useNavigate();
    const footerVariants = {
        hidden: { opacity: 0, y: 20 },
        visible: { opacity: 1, y: 0, transition: { duration: 0.8, ease: 'easeOut' } },
    };

    return (
        <motion.footer
            initial="hidden"
            animate="visible"
            variants={footerVariants}
            className="bg-gray-100 dark:bg-gray-900 py-8 mt-16 transition-colors duration-300 shadow-lg"
        >
            <div className="container mx-auto px-4 text-center">
                {/* Main Footer Text */}
                <p className="text-lg text-gray-700 dark:text-gray-300 mb-4">
                    &copy; 2024 SpotterTools.org. All rights reserved.
                </p>

                {/* Footer Links */}
                <motion.div
                    initial="hidden"
                    animate="visible"
                    variants={footerVariants}
                    className="flex justify-center space-x-6"
                >
                    {/* Privacy Policy Button */}
                    <Button
                        variant="link"
                        onClick={() => navigate('/privacy')}
                        className="text-blue-600 hover:text-blue-800 dark:text-blue-400 dark:hover:text-blue-300 transition-all duration-300"
                    >
                        Privacy Policy
                    </Button>

                    {/* Terms of Service Button */}
                    <Button
                        variant="link"
                        className="text-blue-600 hover:text-blue-800 dark:text-blue-400 dark:hover:text-blue-300 transition-all duration-300"
                        onClick={() => navigate('/terms')}
                    >
                        Terms of Service
                    </Button>

                    {/* Contact Us Button */}
                    <Button
                        variant="link"
                        className="text-blue-600 hover:text-blue-800 dark:text-blue-400 dark:hover:text-blue-300 transition-all duration-300"
                        onClick={() => navigate('/contact')}
                    >
                        Contact Us
                    </Button>
                </motion.div>

                {/* Social Media Links or Icons Section (Optional) */}
                <div className="flex justify-center mt-8 space-x-4">
                    {/* Placeholder for social media icons */}
                    {/* Example: */}
                    {/* <FacebookIcon className="text-gray-700 dark:text-gray-300 hover:text-blue-600" /> */}
                    {/* <TwitterIcon className="text-gray-700 dark:text-gray-300 hover:text-blue-600" /> */}
                    {/* <LinkedInIcon className="text-gray-700 dark:text-gray-300 hover:text-blue-600" /> */}
                </div>
            </div>
        </motion.footer>
    );
};

export default Footer;
